@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.ProjectProductManager-root-container {
    font-family: 'Montserrat', sans-serif;
    background-color: lavender;
    padding-bottom: 20px;
}

.TicketsInHomeComponent-left-sidebar-single-item {
    background-color: rgb(76 69 38);
    color: #ffffff;
    padding: 15px 15px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid rgb(135 123 70);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TicketsInHomeComponent-content {
    width: 95%;
    margin: 0px auto;
    background-color: rgb(76 69 38);
    display: flex;
}

.TicketsInHomeComponent-left-sidebar {
    width: 25%;
    height: 100%;
}

.TicketsInHomeComponent-right-detail {
    width: 75%;
    background-color: rgb(104, 94, 55);
}

.TicketsInHomeComponent-right-detail {
    color: #ffffff;
    padding: 20px 20px 50px 20px;
}

.TicketsInHomeComponent-right-detail-content-values {
    font-size: 18px;
}

.TicketsInHomeComponent-right-detail-content-label {
    color: rgb(169 162 130);
    font-weight: 500;
}

.ProjectProductManager-add-activity-form-container input {
    width: 100%;
    padding: 5px 10px;
}

.ProjectProductManager-add-activity-form-container button {
    display: block;
    margin-left: auto;
    border: none;
    outline: none;
    background-color: #011401;
    color: #ffffff;
    padding: 5px 10px;
}

.ProjectProductManager-activity-list-container-table {
    width: 100%;
    border: 1px solid black;
}

.ProjectProductManager-activity-list-container-table thead tr {
    background-color: #000000;
}

.ProjectProductManager-activity-list-container-table thead tr th {
    padding: 10px 5px
}

.ProjectProductManager-activity-list-container-table tbody tr td {
    padding: 10px 10px
}

.ProjectProductManager-add-activity-form-container {
    border: 2px solid black;
    padding: 10px;
}

/* click checkbox starts */
.HomePageAdmin-select-panel-to-delete {
    width: unset;
    height: unset;
    border: unset;
    background-color: unset;
    display: inline-block;
    color: unset;
    font-weight: bold;
    padding: 0px;
    cursor: pointer;
    position: relative;
}

.HomePageAdmin-select-panel-to-delete label {
    cursor: pointer;
}

.HomePageAdmin-select-panel-to-delete span {
    width: 32px;
    height: 32px;
    display: block;
    border: 1px solid #000000;
}

.HomePageAdmin-select-panel-to-delete span img {
    width: 28px;
    height: 28px;
    object-fit: contain;
}


.active_status_button {
    background-color: unset;
    border: unset;
    color: #ffffff !important;
}

.active_status_button span {
    border: 2px solid #484813;
    background-color: #403e2f !important;
}

.show-element {
    display: block;
}

.hide-element {
    display: none;
}


.invisible-element {
    opacity: 0;
    ;
}

/* click checkbox ends */

.TicketsInHomeComponent-left-sidebar-single-item-name-bar-container {
    width: 100%;
}

.TicketsInHomeComponent-work-complete-percentage-bar {
    width: 100%;
    height: 20px;
    background-color: #000000;
    border-radius: 36px;
}

.TicketsInHomeComponent-work-complete-percentage-bar-value {
    width: 80%;
    height: 20px;
    background-color: #a3951b;
    border-radius: 36px;
    position: absolute;
    top: 0px;
    z-index: 2;
}

.TicketsInHomeComponent-work-complete-percentage-value{
    width: 20%;
    height: 20px;
    background-color: #e4e0bd;
    color: #000;
    border-radius: 36px;
    position: absolute;
    top: 0px;
    z-index: 3;
}

.TicketsInHomeComponent-work-complete-percentage-bar-and-value-container {
    position: relative;
}