@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');

.ClientRegistration-root-container{
    display: flex;
}
.ClientRegistration-cubix-logo{
    position: absolute;
    margin: 40px 0px 20px 40px;
}

.ClientRegistration-left-container{
    height: 100vh;
}

.ClientRegistration-left-container .ClientRegistration-side-banner-img{
    height: 100vh;
    object-fit: cover;
}
.ClientRegistration-top-layer-container div p {
    
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}
h1{
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;   
    font-weight: 600;
    font-size: 28px;
}

h5{
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
}

.ClientRegistration-bg-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ClientRegistration-bg-image-container{
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.ClientRegistration-top-layer-container{
    background: linear-gradient(rgba(84, 114, 191, 0.62), rgba(84, 114, 191,1));
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ClientRegistration-form-container {
    display: flex;
    flex-direction: column;
    width: 26vw;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #767676;
    padding: 20px 30px 40px 30px;
}

.ClientRegistration-form-container label{
    font-family: 'Poppins', sans-serif;
}

.ClientRegistration-form-container input{
    background-color: ghostwhite;
    padding: 10px 15px;
    border: none;
    border: 1px solid #bebebe;
}


.ClientRegistration-login-button {
    background-color: #1094A9;
    border: none;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    padding: 5px 0px;
    color: #ffffff;
    margin-top: 20px;
}
.ClientRegistration-top-dot{
    width: 20px;
    position: absolute;
    bottom: 70px;
    right: 20px;
}
.ClientRegistration-bottom-dot {
    width: 90px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.ClientRegistration-side-dot-panel {
    width: 50px;
    position: absolute;
    bottom: 150px;
    right: 30px;
}

.ClientRegistration-already-have-an-account {
    
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 20px;
    background-color: #5472bf;
    margin-top: 20px;
    color: #ffffff;
}


.ClientRegistration-already-have-an-account button{
    
    font-family: 'Poppins', sans-serif;
    border: none;
    border-radius: 5px;
    margin-left: 20px;
    padding: 5px 10px ;
}

.ClientRegistration-registration-error-container {
    text-align: center;
    color: red;
    font-family: 'Poppins', sans-serif;
    padding: 20px 5px 5px 5px;
}