.CustomerCreateTickets-root-outer-container{
    background-color: #39507C;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: scroll;
}


.CustomerCreateTickets-root-container{
   
    width: 100%;
    padding-top: 20px;
    position: static;
}

.CustomerCreateTickets-root-container div{
    width: 80%;
    margin: 0px auto;
}
.CustomerCreateTickets-root-container input{
    background-color: ghostwhite;
    padding: 10px 15px;
    width: 100%;
    border: none;
    border: 1px solid #bebebe;
    font-family: 'Poppins', sans-serif;
}

.CustomerCreateTickets-root-container input:nth-child(1),
.CustomerCreateTickets-root-container input:nth-child(2){
    margin-right: 5%;
}

.CustomerCreateTickets-attach-image-bg {
    
    border: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: #E5E5E5;
    padding: 5px 20px;
    background-color: #000000;
}


.CustomerCreateTickets-create-button {
    display: block;
    background-color: #F4AC20;
    border: none;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    padding: 5px 30px;
    margin-left: auto;
    margin-right: auto;
}

.CustomerCreateTickets-attach-img-note{
    color: #ffffff;
    font-weight: 300;
}
.CustomerCreateTickets-footer-pattern-background{
    width: 100% !important;
    position: absolute;
    z-index: -1;
    background-image: url("../img/bright_squares_img.png");
    top:0px;
    margin-top: 50px!important;
}

.CustomerCreateTickets-cover-layer{
    
    width: 100% !important;
    height: 500px;
    z-index: -1;
    position: absolute;
    margin-top: 50px!important;
    top: 0px;
}

.CustomerCreateTickets-details-about-issue{
    background-color: ghostwhite;
    padding: 10px 15px;
    width: 100%;
    border: none;
    border: 1px solid #bebebe;
    font-family: 'Poppins', sans-serif;
}

.CustomerCreateTickets-bottom-illustration{
    position: absolute;
    z-index: -1;
    bottom: 0px;
    right: 0px;
}


.AdminHome-work-entry-successPopup-outer{
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdminHome-work-entry-successPopup-inner{
    background-color: #ffffff;
    padding: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.AdminHome-work-entry-successPopup-inner div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdminHome-work-entry-successPopup-inner span{
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.AdminHome-work-entry-successPopup-inner button{
    top: 0px;
    right: 0px;
    border: none;
    background-color: #1094A9;
    color: #ffffff;
    padding: 1px 10px;
}

.AdminHome-work-entry-successPopup-inner img{
    height: 50px;
}

.ClientRegistration-registration-error-container {
    text-align: center;
    color: red;
    font-family: 'Poppins', sans-serif;
    padding: 20px 5px 5px 5px;
}

.CustomerCreateTickets-person-details-container{  
    display: flex;
    justify-content: space-between;
}
.CustomerCreateTickets-person-details-container span{
    width: 30%;
}
.CustomerCreateTickets-error-paragraph{
    text-align: center;
    color: red;
    font-family: 'Poppins', sans-serif;
}