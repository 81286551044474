.AnalyticsPageAdmin-root-container {
    width: 100%;
    height: 100vh;
}

.AnalyticsPageAdmin-inner-container {
    width: 95%;
    max-width: 1400px;
    margin: 0px auto;
}

.AnalyticsPageAdmin-inner-heading-container {
    display: flex;
    justify-content: space-between;
}

.AnalyticsPageAdmin-inner-heading-splitview-container {
    display: flex;
    align-items: center;
}

.AnalyticsPageAdmin-chart-box {
    position: relative;
    height: calc(100vh - 190px);
    background-image: url(/public/header_icons/svg_dot_pattern.svg);
    background-repeat: repeat;
}

.bar {
    fill: #aaa;
    height: 21px;
    transition: fill 0.3s ease;
    cursor: pointer;
    font-family: Helvetica, sans-serif;
}

.bar {
    fill: #558b2f;
}

.bar text {
    fill: #555;
}

/* .chart{
    background-color: yellow;
} */

.chart:hover .bar,
.chart:focus .bar {
    fill: #aaa;
}


.bar:hover,
.bar:focus {
    fill: red !important;
}

.bar:hover text,
.bar:focus text {
    fill: red;
}


.AnalyticsPageAdmin-footer {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #fbfbfb;
    z-index: -1;
}

.AnalyticsPageAdmin-chart-box-graph-line-container {
    width: 100%;
    height: 100%;
    
}

.AnalyticsPageAdmin-chart-box-graph-line-svg {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.AnalyticsPageAdmin-chart-box-step-unit-container {
    background-color: #777777;
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0px;
}

.AnalyticsPageAdmin-chart-box-step-unit-container-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #777777;
    margin-top: 10px;
}

.AnalyticsPageAdmin-splitted-bar-container-hidden {
    display: none;
}

.AnalyticsPageAdmin-company-name {
    padding: 20px 0px 4px 0px;
}

.AnalyticsPageAdmin-company-name-chip {
    color: #ffffff;
    border-radius: 5px;
    padding: 2px 15px;
    background-color: black;
}

.AnalyticsPageAdmin-chart-box-single-row {
    padding: 0px 0px 20px 0px;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.AnalyticsPageAdmin-inner-heading-splitview-container {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #b8afaf;
    border: 1px solid #f1f1f1;
}

.AnalyticsPageAdmin-inner-heading-splitview-container:hover {
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #c7bfbf;
}

.splitview-enabled {
    background-color: #fff3dc !important;
    color: #ffa500 !important;
}

.AnalyticsPageAdmin-inner-heading-splitview-container img {
    height: 20px;
}

.AnalyticsPageAdmin-chart-box-company-list-container{
    height: 100%;
    overflow-y: scroll;
}
