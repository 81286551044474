@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


.TicketsInHomeComponent-root-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.TicketsInHomeComponent-header {
    position: relative;
    display: flex;
    width: 95%;
    margin: 0px auto;
    height: 70px;
}

.TicketsInHomeComponent-header img {
    position: relative;
    left: 0px;
}

.TicketsInHomeComponent-header-right-section {
    display: flex;
    flex-direction: column;
    color: #3a58a5;
}

.TicketsInHomeComponent-header-right-section div {
    display: flex;
}

.TicketsInHomeComponent-header-right-section button {
    border: none;
    outline: none;
    border-radius: 5px;
    color: #ffffff;
    background-color: #0A77B1;
    padding: 4px 12px;
}

.TicketsInHomeComponent-header-right-section a {
    border: none;
    outline: none;
    border-radius: 5px;
    color: #ffffff;
    background-color: #0A77B1;
    padding: 4px 12px;
    text-decoration: none;
}

.TicketsInHomeComponent-list-container {
    padding: 10px 20px 10px 20px;
    height: calc(100vh - 150px);
    /* background-color: aqua; */
}

.TicketsInHomeComponent-statistics-container{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.TicketsInHomeComponent-statistics-container-company-search-box-selected {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    border: 5px solid black;
}

.TicketsInHomeComponent-statistics-container img{
    width: 25px;
    height: 28px;
    object-fit: contain;
    cursor: pointer;
}

.TicketsInHomeComponent-statistics-container-company-search-box-selected img{
    width: 25px;
    height: 28px;
    object-fit: contain;
    cursor: pointer;
}

.TicketsInHomeComponent-statistics-container-img-bg{
    border-radius: 36px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.TicketsInHomeComponent-statistics-container-img-bg-selected{
    border-radius: 36px;
    width: 45px;
    height: 45px;
    box-shadow: 0px 1px 2px #3a58a5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}
.TicketsInHomeComponent-statistics-container-img-bg:hover{
    background-color: yellow;
}
.TicketsInHomeComponent-statistics-container-img-bg:hover{
    background-color: #dde6e6;
    cursor: pointer;
}

.TicketsInHomeComponent-menu-cta-container{
    display: flex;
    justify-content: end;
    align-items: center;
}

.TicketsInHomeComponent-menu-cta-container-company-search-box-selected{
    display: flex;
    justify-content: end;
    align-items: center;
    border: 5px #000000 solid;
}

.image-ticket-above-table-icons{
    width: 30px;
    height: 30px;
}

.bg-ticket-above-table-icons{
    border: none;
    border-radius: 36px;
    background-color: #ffffff;
    padding: 4px 10px;
    text-decoration: none;
    color: #2f4f4f;
    display: flex;
    align-items: center;
}

.Ticket-logo-container{
    display: flex;
    align-items: center;
}

.Ticket-logo-container img {
    height: 50px;
}

.ShowTicketByStaff-container-outer{
    background-color: rgba(0,0,0,0.85);
    width: 95%;
    height:  calc(100vh - 80px);
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShowTicketByStaff-container-inner{
    background-color: #ffffff;
    padding: 10px;
    width: 80%;
    height: 80%;
}

.ShowTicketByStaff-container-inner-search-box-and-list-container {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
}

.ShowTicketByStaff-container-inner-search-box-and-list {
    position: absolute;
    width: auto;
    z-index: 3;
    right: 0px;
    top: 40px;
    background-color: #353535;
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
    border: 1px #263238 solid;
}

.ShowTicketByStaff-container-inner-search-box {
    width: 400px;
}

.ShowTicketByStaff-container-list-single-row:hover td{
    background-color: red !important;
    cursor: pointer;
}

.TicketsInHomeComponent-company-details-container-inner{
    background-color: #000000;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding: 10px;
}

.TicketsInHomeComponent-company-details-container-inner{
    display: flex;
    justify-content: space-between;
}

.TicketsInHomeComponent-company-details-container-inner div{
    display: flex;
    flex-direction: column;
}

.ShowTicketByStaff-header-panel{
    display: flex;
    justify-content: space-between;
}

.home_page_support_person_list {
    background-color: #ececec;
    padding: 20px;
    position: absolute;
    width: 80%;
    z-index: 3;
    height: 300px;
    overflow-y: scroll;
    border: 1px solid #978e8e;
}

@media (max-width: 575.98px) {

    .TicketsInHomeComponent-header {
        overflow-x: scroll;
    }

    .TicketsInHomeComponent-header img {
        position: relative;
    }

    .TicketsInHomeComponent-header-right-section div {
        display: flex;
    }

    .TicketsInHomeComponent-header-right-section {
        flex-direction: unset;
    }

    .TicketsInHomeComponent-menu-cta-container button,
    .TicketsInHomeComponent-menu-cta-container a {
        min-width: 200px;
    }

}