.ReportIssuePopup-root-container {
    width: 100vw;
    height: 100vh;
    padding: 20px 0px;
    position: fixed;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.8);
}

.ReportIssuePopup-inner-container {
    background-color: #ffffff;
    border-radius: 5px;
    overflow-x: hidden;
    width: 80%;
    margin: 0px auto;
    max-width: 900px;
}

.ReportIssuePopup-success-container-sent-gif {
    width: 20%;
    display: block;
    margin: 0px auto;
}

.ReportIssuePopup-success-container {
    padding: 20px 20px 40px 20px;
    text-align: center;
}

.ReportIssuePopup-success-container-sent-text {
    color: #605e5e;
}

.ReportIssuePopup-inner-container-ok-button {
    border: none;
    outline: none;
    background-color: aquamarine;
    padding: 4px 30px;
    color: #000000;
    border-radius: 5px;
}

@media (max-width: 575.98px) {
    .ReportIssuePopup-success-container-sent-gif {
        width: 30%;
    }

}