
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.TicketDetailsSupport-root-container {
    font-family: 'Montserrat', sans-serif;
}

.TicketDetailsSupport-main-container{
    width: 90%;
    margin: 0px auto 50px auto;
    max-width: 900px;
    border-radius: 5px;
    padding: 10px 20px 10px 20px ;

}

.TicketDetails-activity-list-single-item{
    width: 90%;
    margin: 0px auto;
    max-width: 900px;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    box-shadow: 2px 2px 16px #888888;
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.TicketDetails-activity-list-icon{
    width: 50px;
    height: 50px;
}
.TicketDetails-date-remark-container{
    display: flex;
    flex-direction: column;
    width: 80%;
}
.TicketDetails-current-staus-image-container{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.Ticket-remark-label{
    min-height: 50px;
    box-shadow: inset 1px 1px 4px #aeaeae;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #f3f3f3;
}

.TicketDetailsSupport-ticket-log-details{
    font-size: 18px;
    color: #000;
    font-weight: 500;
    display: block;
    text-align: center;
}
.TicketDetailsSupport-ticket-log-details-underline{
    width: 63px;
    height: 4px;
    display: block;
    background-color: #468f5c;
    margin: 0px auto;
    border-radius: 36px;
}

.TicketDetailsSupport-panel{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.TicketDetailsSupport-panel-value{
    font-size: 18px;
    font-weight: 500;
}

.TicketDetailsSupport-panel-label{
    color: gray;
    font-weight: 500;
    font-size: 15px;
}

.TicketDetailsSupport-ticket-opened-panel {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.TicketDetailsSupport-attached-file-name-bg{
    background-color: #468f5c;
    padding: 4px 15px;
    border-radius: 36px;
    cursor: pointer;
    color: #ffffff;
    border: 1px #468f5c solid;
}

.TicketDetailsSupport-attached-file-name-bg:hover{
    background-color: #ffffff;
    color: #468f5c;
    border: 1px #468f5c solid;
}

.TicketDetails-datetime-and-timetaken{
    display: flex;
    justify-content: space-between;
}
.TicketDetails-time-taken-label-container{
    display: flex;
    align-items: center;
}
.TicketDetails-time-taken-label-container img{
    height: 30px;
}


