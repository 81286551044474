.SideDrawer-root-container {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 5;
    font-family: 'Montserrat', sans-serif;
}

.SideDrawer-inner-container {
    background-color: #ffffff;
    width: 30%;
    height: 100%;
    margin-left: auto;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.SideDrawer-inner-container-heading-container {
    display: flex;
    justify-content: space-between;
}

.SideDrawer-inner-container-heading-container label {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 2px solid #767676;
}

.SideDrawer-inner-container-menu-child a {
    background-color: #ffffff;
    display: block;
    padding: 10px 0px 10px 20px;
    cursor: pointer;
    color: #39a38a;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
}

.logout-container>img {
    width: 35px !important;
    margin-left: 15px;
    margin-right: 15px;
}




@media (max-width: 575.98px) {
    .SideDrawer-inner-container {
        width: 100%;
    }
}