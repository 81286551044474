@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');

.AdminLogin-root-container{
    display: flex;
}
.AdminLogin-cubix-logo{
    position: absolute;
    margin: 40px 0px 20px 40px;
}

.AdminLogin-left-container{
    height: 100vh;
}

.AdminLogin-left-container .AdminLogin-side-banner-img{
    height: 100vh;
    object-fit: cover;
}
.AdminLogin-top-layer-container div p {
    
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}
h1{
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;   
    font-weight: 600;
    text-shadow: 0 0 3px #525252;
    font-size: 28px;
}

h5{
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
}

.AdminLogin-bg-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.AdminLogin-bg-image-container{
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.AdminLogin-top-layer-container{
    background: #3a3939;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.AdminLogin-form-container {
    display: flex;
    flex-direction: column;
    width: 26vw;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #767676;
    padding: 20px 30px 40px 30px;
}

.AdminLogin-form-container label{
    font-family: 'Poppins', sans-serif;
}

.AdminLogin-form-container input{
    background-color: ghostwhite;
    padding: 10px 15px;
    width: 100%;
    border: none;
    border: 1px solid #bebebe;
}


.AdminLogin-login-button {
    background-color: #1094A9;
    border: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}
.AdminLogin-top-dot{
    width: 20px;
    position: absolute;
    bottom: 70px;
    right: 20px;
}
.AdminLogin-bottom-dot {
    width: 90px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.AdminLogin-side-dot-panel {
    width: 50px;
    position: absolute;
    bottom: 150px;
    right: 30px;
}

.AdminLogin_register_now_panel{
    background-color: #ffffff;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
}

.AdminLogin_register_now_panel label{
    font-family: 'Poppins', sans-serif;
}

.AdminLogin_register_now_panel a{
    background-color: #fbda36;
    padding: 10px 15px;
    text-decoration: none;
    color: #3d3056;
    border-radius: 5px;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
}

.AdminLogin-registration-error-container {
    text-align: center;
    color: red;
    font-family: 'Poppins', sans-serif;
    padding: 20px 5px 5px 5px;
}

.AdminLogin-person-icon-heading-container {
    display: flex;
    background-color: rgba(0, 38, 255, 0.315);
    justify-content: space-around;
    align-items: center;
}


.AdminLogin-person-icon-heading-container img {
    background-color: #ffffff;
    box-shadow: 2px 2px 12px #524f4f;
    border-radius: 5px;
    padding: 15px;
}

@media (max-width: 575.98px){
    .AdminLogin-top-layer-container {
        position: absolute;
        top: 0px;
    }
    .AdminLogin-person-icon-heading-container {
        flex-direction: column;
    }
    .AdminLogin-form-container {
        width: 75%;
        margin: 0px auto;
    }
    .hidden-in-mobile{
        display: none !important;
    }
    .AdminLogin-top-layer-container {
        justify-content: unset;
        padding: 20px 0px 40px 0px;
        overflow-y: scroll;
    }

    .AdminLogin-heading {
        text-align: center;
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        padding: 20px;
    }
    .AdminLogin-person-icon-heading-container img {
        background-color: #ffffff;
        box-shadow: 2px 2px 12px #524f4f;
        border-radius: 5px;
        padding: unset;
    }
    .AdminLogin-errorMessage {
        font-family: 'Poppins', sans-serif;
        color: #ed3f3f !important;
    }
}