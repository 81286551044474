@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');


.TabHeader-root-container{ 
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  height: 130px;
}
.TabHeader_company_logo{
    height: 50px;
  }
  .TabHeader-top-phone-number-container img {
    height: 22px;
    margin: 0px 15px 0px 50px;
  }
  
  .TabHeader-top-phone-number-container-divider-slash {
    margin: 0px 10px;
  }
  
  .TabHeader-right-section {
    margin-left: auto;
  }
  .TabHeader-top-bar {
    margin: 0px auto !important;
  }

  .TabHeader-logout-container{
    padding:5px 15px;
    border-radius: 5px;
    font-size: 14px;
    background-color: #535050;
    color: #ffffff;
    cursor: pointer;
  }
  
  .TabHeader-logout-container img{
    width: 20px;
  }
  
  .TabHeader-top-navbar {
    background-color: #ffffff;
    padding: 0px 60px;
    height: 66px;
    position: relative;
  }
  
  .TabHeader-nav-container .nav-link {
    color: rgba(0, 0, 0, 0.62) !important;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  
  .TabHeader-nav-container .active {
    color: #d6c94b !important;
    border-bottom: 5px solid #d6c94b;
    font-family: 'Poppins', sans-serif;
  }

  .nav-link{
    padding: 0.7rem 1rem !important;
  }

  .navbar-collapse{
    padding: 0px 0px 0px 0px;
  }

  .TabHeader-company-name-container h3{
    font-family: 'Poppins', sans-serif;
    color: #6c5959;
    padding-bottom: 9px;
    font-size: 18px;
    font-weight: 500;
  }

  .TabHeader-right-side-logout-column {
    display: flex;
    flex-direction: column;
    background-color: #fdfdfd;
    box-shadow: 0px 0px 5px #919191;
    padding: 30px 20px;
    top: 50px;
    right: 20px;
    position: absolute;
    z-index: 3;
    border-radius: 2px;
}


.TabHeader-right-account-container button {
  border: none;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  padding: 5px 10px;
}
.TabHeader-right-account-container button img{
  margin-right: 10px;
  height: 20px;
}

.TabHeader-img-menu-hamburger{
  width: 30px;
}

.TabHeader-container-top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 20px;
}

.TabHeader-top-panel-button {
  display: flex;
  align-items: center;
  font-size: 16px;
  border: none;
  background-color: #dcdcdc;
}
.TabHeader-container-bottom-panel ul{
  justify-content: center;
    background-color: #ffffff;
    border: 1px solid #bdc6d7;
    display: inline-flex;
    padding: 0px 5px;
    border-radius: 5px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #39507C;
}

.TabHeader-container-bottom-panel {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding: 5px;
}
.nav-pills .nav-link {
  color: #39507C;
}

