@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.CustomerDetails-root-container {
    font-family: 'Montserrat', sans-serif;
}

.CustomerDetails-table {
    width: 95%;
    margin: 0px auto;
    border: 1px solid #5f9ea0;
}

.CustomerDetails-table thead tr th div {
    padding: 15px 5px;
    color: #ffffff;
}

.CustomerDetails-table tbody tr td div {
    padding: 10px 15px;
}

.CustomerDetails-table tr:nth-child(odd) td {
    background: rgb(213 255 216);
}

.CustomerDetails-table tr:nth-child(even) td {
    background: #a7dbab;
}

.CustomerDetails-table>th>td {
    border: 2px solid #3f8b93;
}

.CustomerDetails-table thead tr th {
    height: 70px;
    background-color: currentcolor;
}

.CustomerDetails-root-container>h5 {
    text-align: left;
    color: #6c5d5d;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    margin-top: 20px;
    padding-left: 20px;
}

.CustomerDetails-filter-button {
    border: none;
    outline: none;
    background-color: #6c5d5d;
    color: #ffffff;
    padding: 5px 25px;
}


.CustomerDetails-company-name-column {
    min-width: 300px !important;
}

.CustomerDetails-company-sl-no-column {
    min-width: 60px !important;
}

.CustomerDetails-company-date-column {
    min-width: 150px !important;
}

.CustomerDetails-left-right-container{
    display: flex;
}

.CustomerDetails-inner-container {
    height: calc(100vh - 100px);
    overflow: scroll;
    width: 90%;
}