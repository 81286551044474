@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.ViewReports-main-container {
    width: 90%;
    margin: 0px auto;
    font-family: 'Montserrat', sans-serif;
}

.ViewReports-company-container{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.ViewReports-datecontainer{
    display: flex;
}

.ViewReports-name-date-and-button-container{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.ViewReports-view-button{
    border: none;
    outline: none;
    background-color: #000000;
    color: #ffffff;
    padding: 10px 30px;
}

.ViewReports-list-of-companies-dropdown{
    position: absolute;
    z-index: 1;
    top: 30px;
    background-color: rgb(233, 225, 215);
    width: 100%;
}

.ViewReports-single-item-company-name{
    display: block;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
}

.ViewReports-single-item-company-name:hover{
    background-color: rgb(94, 115, 133);
    color: #ffffff;
}

.ViewReports-dropdown-holder{
    position: relative;
}

.ViewReports-search-company-input{
    width: 100%;
}

.ViewReports-table  thead {
    box-shadow: 0px 1px 22px #9a9a9a;
}

.ViewReports-table-container{
    height: calc(100vh - 330px);
    overflow: scroll;
}

.Download-excel-button{
    border: none;
    outline: none;
    background-color: #006400;
    color: #ffffff;
    padding: 10px 30px;
}

.Download-excel-button-img {
    width: 25px;
}

.view-button-and-error-message-container{
    display: flex;
    flex-direction: column;
}

.ViewReports-popup-outer-container{
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ViewReports-popup-inner-container{
    width: 30vw;
    height: 30vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ViewReports-empty-error{
    font-size: 18px;
    color: #000000;
    font-weight: 500;
}

.ViewReports-left-right-container{
    display: flex;
    height: calc(100vh - 280px);
}

.ViewReports-company-details-after-selection{
    display: flex;
    justify-content: space-between;
    background-color: #e7e7e7;
}

.ViewReports-company-details-after-selection div{
    border: 1px solid #7d7d7d;
    width: 100%;
}

.ViewReports-company-details-after-selection div label{
    background-color: #000000;
    display: block;
    padding: 5px;
    color: #ffffff;
}

.ViewReports-company-details-after-selection div p{
    padding: 5px;
}

.ViewReports-company-search-result-list-container{
    right: unset;
}