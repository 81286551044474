@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


.AmcOfCompanies-root-container{
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
}

.AmcOfCompanies-inner-container{
    width: 90%;
    margin: 0px auto;
    height: calc(100vh - 120px);
}

.AmcOfCompanies-table-container{
    height: calc(100vh - 220px);
    overflow-y: scroll;
}

.AmcOfCompanies-table {
    width: 100%;
    position: relative;
}

.AmcOfCompanies-table thead{
    background-color: #000000;
    color: #ffffff;
    position: sticky;
    top: 0px;
}


.AmcOfCompanies-table thead tr th div{
    padding: 10px;
}

.AmcOfCompanies-table tr:nth-child(odd) td {
    background: rgb(213 255 216);
    font-size: 20px ;
}

.AmcOfCompanies-table tr:nth-child(even) td {
    background: #a7dbab;
    font-size: 20px ;
}

.AmcOfCompanies-table tr td div{
    padding: 10px;
}

.AmcOfCompanies-dropdown-month-container{
    position: absolute;
    z-index: 3;
    top:40px;
    right: 0px;
    height: 200px;
    min-width: 180px;
    overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px #000000 solid;
}

.AmcOfCompanies-select-amc-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AmcOfCompanies-single-list-item{
    padding: 5px 25px;
    font-size: 18px;
}

.AmcOfCompanies-single-list-item:hover{
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
}

.AmcOfCompanies-select-month {
    background-color: black;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
}

.AmcOfCompanies-current-month {
    color: #000000;
    padding: 2px 10px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;

}

.AmcOfCompanies-show-all-amc-list{
    background-color: #ebebeb;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.AmcOfCompanies-left-right-container{
    display: flex;
}