.node_name {
    background-color: aliceblue;
}

.checkbox_image {
    width: 30px;
    height: 30px;
    border: 2px solid #767676;
    display: inline-block;
}

.checklist_table {
    width: 100%;
}

.checklist_table_single_row td{
    padding: 4px 10px;
}

.checklist_table_subheading{
    padding: 10px;
}

.checklist_bottom_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.popup_header_banner {
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 3;
    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.checklist_table_container{
    overflow: scroll;
}

.popup-outer{
    background-color: #00000080;
    position: fixed;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner{
    width: 90%;
    height: 90%;
    background-color: #ffffff;
    overflow: scroll;
    padding: 10px;
}

.checkbox_image_in_previous_list {
    width: 30px;
    height: 30px;
    border: 2px solid #efefef;
    display: inline-block;
    background-color: aliceblue;
}

.row-of-staff td{
    background-color: #f9f9f9;
    border: 2px solid #ffffff;
    padding: 4px 10px;
    border-radius: 5px;
}