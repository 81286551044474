.SideSmallDrawer-root-container{
    /* background-color: #8744D9; */
}


.SideSmallDrawer-option-container{
    color: #ffffff;
    font-size: 12px;
    /* background-color: #000000; */
}

.SideSmallDrawer-cubix-logo{
    width: 60px;
}

.small-side-drawer-option-images{
    display: block;
    width: 40px;
    padding: 4px;
}

.small-side-drawer-option-images:hover{
    background-color: #e4e3e3;
    border-radius: 36px;
    cursor: pointer;
    padding: 4px;
}

.SideSmallDrawer-icon-name-container{
    display: flex;
    align-items: center;
}