.CreateTaskPopupContainer-root-container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreateTaskPopupContainer-inner-card {
    position: absolute;
    width: 50%;
    height: 75vh;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding: 30px 20px 30px 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.CreateTaskPopupContainer-inner-card input {
    background-color: #F3F3F3;
    border: 1px solid #838383;
}

.CreateTaskPopupContainer-company-search-box {
    background-color: #ffffff !important;
    border: 1px solid #0c84a8 !important;
    width: 100%;
    padding: 2px 5px 2px 10px;
    position: sticky !important;
    top: 0px !important;
}

.CreateTaskPopupContainer-Expected-Completion-Date-Time-container {
    display: flex;
    align-items: center;
}

.CreateTaskPopupContainer-pick-bg {
    background-color: #3A3535;
    padding: 5px 15px;
    color: #ffffff;
}

.CreateTaskPopupContainer-side-clip-image {
    position: absolute;
    width: 70px;
    top: 70px;
    left: 23%;
    z-index: 10;
}

.CreateTaskPopupContainer-cta-pick-date,
.CreateTaskPopupContainer-cta-pick-time {
    cursor: pointer;
}

.CreateTaskPopupContainer-cancel-save-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.CreateTaskPopupContainer-cancel-button,
.CreateTaskPopupContainer-save-button {
    border: none;
    outline: none;
    padding: 5px 10px;
}

.CreateTaskPopupContainer-cancel-button {
    background-color: #838383;
    color: #ffffff;
    padding: 5px 20px;
}

.CreateTaskPopupContainer-save-button {
    background-color: #5472BF;
    color: #ffffff;
    padding: 5px 20px;
}

.CreateTaskPopupContainer-staff-dropdown-anchorand-list-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.CreateTaskPopupContainer_staff_name_dropdown_item_container {
    position: absolute;
    top: 50px;
    z-index: 5;
    background-color: #aed581;
    height: 140px;
    width: 100%;
    overflow-y: scroll;
}

.CreateTaskPopupContainer_staff_name_dropdown_item_container span {
    padding: 5px 20px;
    margin: 10px 0px;
    cursor: pointer;
}

.CreateTaskPopupContainer_staff_name_dropdown_item {
    display: block;
}

.CreateTaskPopupContainer-dropdown-picker {
    background-color: #404040;
    border: 1px solid gainsboro;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: #ffffff;
}

.CreateTaskPopupContainer-error-message-container {
    margin-top: 10px;
}

.CreateTaskPopupContainer-error-message-container p {
    margin-bottom: 0px;
}

@media(max-width:575.98px) {
    .CreateTaskPopupContainer-side-clip-image {
        position: absolute;
        width: 32px;
        top: 70px;
        left: 10%;
        z-index: 10;
    }

    .CreateTaskPopupContainer-inner-card {
        width: 75%;
    }

    .CreateTaskPopupContainer-inner-card {
        padding: 30px 20px 30px 30px;
    }
}