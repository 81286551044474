@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.Header-root-container {
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    height: 80px;
    display: flex;
    align-items: center;
}

.Header-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0px auto;
}


.Header-panel button {
    background-color: #FFFFFF;
    border: 0px solid #ffffff;
    padding: 4px 12px;
}

.Header-panel button img {
    height: 20px;
}

.Header-panel button label {
    margin-left: 10px;
    color: #263238;
    font-weight: 500;
    cursor: pointer;
}

.Header-support-bg-text-as-bg {
    font-size: 52px;
    color: #f3f3f3;
    font-weight: 700;
    position: absolute;
    top: -33px;
    right: 3%;

}

.Header-panel-company-logo img {
    width: 200px;
}

.Header-company-search-box-and-list-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.Header-company-search-box-and-list-container img {
    width: 20px;
    height: 20px;
}

.Header-company-search-box-and-list-container input {
    width: 100%;
    padding: 5px;
    border: none;
    outline: none;
    border-bottom: 2px #908f8f solid;
}

.Header-company-search-result-list-container {
    position: absolute;
    width: 80vw;
    z-index: 3;
    right: 0px;
    top: 40px;
    background-color: #353535;
    height: 50vh;
    overflow-y: scroll;
    border: 1px #263238 solid;
}

.Header-company-search-result-list-single-item {
    padding: 5px 10px;
    font-size: 18px;
    color: #ffffff;
}

.Header-company-table-after-search tr:nth-child(odd) td {
    background: #343634;
}

.Header-company-table-after-search tr:nth-child(even) td {
    background: #434141;
}

.Header-company-table-after-search thead {
    position: sticky;
    top: 0px;
    border: none;
}

.Header-company-table-after-search thead tr th div {
    background-color: #000000;
    color: #ffffff;
    padding: 5px;
}

.Header-searching-text-container {
    color: #ffffff;
    font-size: 20px;
    padding: 20px;
    border: none;
    display: flex;
}

.Header-company-table-after-search {
    width: 100%;
}

.Header-company-search-result-list-single-row:hover td {
    background-color: red !important;
    cursor: pointer;
}

.Header-company-search-box-remove-and-input-container {
    display: flex;
    width: 30%;
}

.Header-company-search-box-remove-chip {
    background-color: red;
    border: 1px red solid;
    color: #ffffff;
    border-radius: 5px;
    padding: 5px 10px;
}
.Header-company-search-box-remove-chip:hover {
    background-color: #ffffff;
    color: red;
    border: 1px red solid;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

@media(max-width:575.98px) {

    .Header-panel>a>img {
        height: 30px;
    }

    .Header-panel button img {
        height: 10px;
    }

    .Header-panel button label {
        font-size: 14px;
        margin-left: 5px;
    }

    .Header-panel button {
        padding: 2px 6px;
    }

    .SideDrawer-inner-container {
        width: 60%;
    }
}