@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.AdminDashboard-root-container {
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.show-only-in-mobile {
    display: none;
}

.show-only-in-desktop {
    display: block;
}

.AdminDashboard-left-child-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.AdminDashboard-left-child-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.Ticket-SideSmallDrawer-container{
    display: flex;
    position: relative;
    /* background-color: #556492; */
    height: calc(100vh - 80px);
}
@media (max-width: 575.98px) {

    .show-only-in-mobile {
        display: block;
    }

    .show-only-in-desktop {
        display: none;
    }

    .AdminDashboard_mobile_only_tab {
        background-color: #ffffff;
        padding: 25px 10px;
        display: flex;
        justify-content: center;
    }

    .AdminDashboard_mobile_only_tab label {
        font-size: 16px;
        font-weight: 600;
    }

    .DailyTasksInHomeComponent-header-right-section h4,
    .TicketsInHomeComponent-header-right-section h4 {
        display: none;
    }

    .DailyTasksInHomeComponent-header-right-section button,
    .DailyTasksInHomeComponent-header-right-section a {
        border: none;
        outline: none;
        border-radius: 5px;
        color: #ffffff !important;
        background-color: #020416 !important;
        padding: 4px 12px;
    }

    .AdminDashboard_mobile_tab_container {
        border: 2px solid #556492;
        border-radius: 5px;
    }

    .active_tab {
        background-color: #556492;
        color: #ffffff;
        padding: 4px 20px;
    }

    .not_active_tab {
        background-color: #ffffff;
        color: #000000;
        padding: 4px 20px;
    }


}