@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');


.AdminCompanyReport-body-summary-container,
.AdminCompanyReport-body-amc-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.AdminCompanyReport-root-container{
    font-family: 'Poppins', sans-serif;

}
.AdminCompanyReport-body-container{
    width: 90%;
    margin: 0px auto;
}
.AdminCompanyReport-body-summary-container-child label{
    display: block;
    text-align: center;
    font-size: 18px;
    color: #000;
}
.AdminCompanyReport-body-container-value{
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    color: #000;
}

.AdminCompanyReport-body-summary-container-child{
    background-color: #b5dbb4;
    padding: 15px;
    margin-top: 20px;
}
.AdminCompanyReport-body-amc-container div{
    background-color: aliceblue;
    padding: 10px 20px;
    margin-top: 20px;
}

.AdminCompanyReport-enter-details {
    display: flex;
    flex-direction: column;
}