.ViewClosedTicketsOfACompany-root-container {
    background-image: linear-gradient(rgba(0, 0, 255, 0.2), rgba(255, 255, 0, 0.2)),
        url("../img/bg_details_of_page.png");
    height: 100vh;
    overflow: scroll;
}

.View-closed-tickets-of-a-company-left-child{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.View-closed-tickets-of-a-company-view-details-link {
    margin-left: auto;
    background-color: #468f5c;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 44px;
    text-decoration: none;
    border: 1px solid #468f5c;
}


.View-closed-tickets-of-a-company-view-details-link:hover {
    background-color: #ffffff;
    color: #468f5c;
    padding: 5px 10px;
    border-radius: 44px;
    text-decoration: none;
    border: 1px solid #468f5c;
}

.View-closed-tickets-of-a-company-view-details-link-container{
    display: flex;
}

.ViewClosedTicketsOfACompany-ticket-search-container {
    width: 100%;
    display: flex;
    justify-content: end;    
    position: relative;
}

.ViewClosedTicketsOfACompany-search-result-list-container {
    width: 90%;
    margin-right: 1%;
}

.ViewClosedTicketsOfACompany-table{
    width: 100%;
}

.ViewClosedTicketsOfACompany-table-first-column{
    width: 40%;
}

.ViewClosedTicketsOfACompany-search-input{
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
    padding-left: 5px;
}