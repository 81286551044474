.Chatbox{
    background-color: #ffffff;
    width: 30%;
    position: fixed;
    bottom: 0px;
    right: 20px;
    height: 50%;
    max-height: 400px;
    border: 1px solid #b2b2b2;
}
.Chatbox-header-container{
    padding: 10px;
    box-shadow: -1px 1px 6px #323232;
    background-color: #468f5c;
    color: #f0f8ff;
    display: flex;
    justify-content: space-between;
}

.Chatbox-body-container{
    padding: 10px 0px 20px 0px;
    height: calc(100% - 100px);
    overflow-y: scroll;
    position: relative;
}

.ChatBox-sender-bubble-container{
    background-color: #c9d1cb;
    color: #000000;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ChatBox-reply-container{
    width: 100%;
    height: 50px;
    background-color: #d8e0db;
    position: absolute;
    bottom: 0px;
    display: flex;
}

.ChatBox-input-send-button-container{
    display: flex;
    justify-content: space-between;
    background-color: #468f5c;
    width: 100%;
}

.ChatBox-input-container-left{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ChatBox-message-typing-input{
    width: 90%;
    display: block;
    padding: 5px;
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
}

.ChatBox-message-typing-input:focus{
    width: 90%;
    display: block;
    padding: 5px;
    border: none;
    border: 2px solid #ac2121;
    outline: none;
}

.ChatBox-send-container-right{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    height: 100%;
}

.ChatBox-send-button{
    border: none;
    outline: none;
    padding: 5px 20px;
    background-color: black;
    color: #f0f8ff;
}

.ChatBox-send-button:hover{
    border: none;
    outline: none;
    padding: 5px 20px;
    border: 1px solid #000000;
    background-color: #ffffff;
    color: #000000;
}

.bubble-left {
    position: relative;
    background: #e8e8e8;
    color: #FFFFFF;
    max-width: fit-content;
    padding: 5px;
    
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 2px 2px 2px #d6d6d6;
    color: #000;
    margin-left: 22px;
    margin-top: 10px;
    margin-right: 20%;
}
.bubble-left::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 22px 20px 0;
    border-color: transparent #e8e8e8 transparent transparent;
    top: 0;
    left: -22px;
}

.bubble-left-inner-container{
    padding: 3px 10px;
}

.bubble-right-outer{
    display: flex;
    justify-content: right;
}

.bubble-right {
    position: relative;
    background: #d1f0d1;
    color: #FFFFFF;
    width: fit-content;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 2px 2px 2px #d6d6d6;
    color: #000;
    margin-right: 22px;
    margin-left: 20%;
    margin-top: 10px;
}
.bubble-right:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 20px 20px 0px 0px;
    border-color: #d1f0d1 transparent transparent transparent;
    top: 0;
    right: -20px;
}

.bubble-right-inner-container{
    padding: 3px 10px;
}

.ChatBox-header-image{
    width: 28px;
}

.ChatBox-header-image-button {
    border: none;
    outline: none;
    background: transparent;
}

.ChatBox-minimize-maximize-button-container{
    display: flex;
}

.Chatbox-header-label{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ChatBox-minimized-header{
    background-color: #ffffff;
    width: 30%;
    position: fixed;
    bottom: 0px;
    right: 20px;
    height: 53px;
    border: 1px solid #b2b2b2;
}

.ChatBox-invisible-element{
    display: none;
}

.ChatBox-loading-screen{
    background-color: #000;
    color: #ffffff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    z-index: 3;
}