@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


.TicketsInHomeComponentTvMonitor-root-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.TicketsInHomeComponentTvMonitor-header {
    position: relative;
    display: flex;
    width: 95%;
    margin: 0px auto;
}

.TicketsInHomeComponentTvMonitor-header img {
    position: relative;
    left: 0px;
}

.TicketsInHomeComponentTvMonitor-header-right-section {
    display: flex;
    flex-direction: column;
    color: #3a58a5;
}

.TicketsInHomeComponentTvMonitor-header-right-section div {
    display: flex;
}

.TicketsInHomeComponentTvMonitor-header-right-section button {
    border: none;
    outline: none;
    border-radius: 5px;
    color: #ffffff;
    background-color: #0A77B1;
    padding: 4px 12px;
}

.TicketsInHomeComponentTvMonitor-header-right-section a {
    border: none;
    outline: none;
    border-radius: 5px;
    color: #ffffff;
    background-color: #0A77B1;
    padding: 4px 12px;
    text-decoration: none;
}

.TicketsInHomeComponentTvMonitor-list-container {
    padding: 10px 20px 10px 20px;
    height: calc(100vh - 200px);
    /* background-color: aqua; */
}

.TicketsInHomeComponentTvMonitor-statistics-container{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.TicketsInHomeComponentTvMonitor-statistics-container-company-search-box-selected {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    border: 5px solid black;
}

.TicketsInHomeComponentTvMonitor-statistics-container img{
    width: 25px;
    height: 28px;
    object-fit: contain;
    cursor: pointer;
}

.TicketsInHomeComponentTvMonitor-statistics-container-company-search-box-selected img{
    width: 25px;
    height: 28px;
    object-fit: contain;
    cursor: pointer;
}

.TicketsInHomeComponentTvMonitor-statistics-container-img-bg {
    background-color: #08c299;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    color: #ffffff;
    height: 80px;
}


.TicketsInHomeComponentTvMonitor-statistics-container-img-bg-second_card {
    background-color:#571179!important;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    color: #ffffff;
}

.TicketsInHomeComponentTvMonitor-statistics-container-img-bg-selected{
    border-radius: 36px;
    width: 45px;
    height: 45px;
    box-shadow: 0px 1px 2px #3a58a5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}
.TicketsInHomeComponentTvMonitor-statistics-container-img-bg:hover{
    background-color: yellow;
}
.TicketsInHomeComponentTvMonitor-statistics-container-img-bg:hover{
    background-color: #dde6e6;
    cursor: pointer;
}

.TicketsInHomeComponentTvMonitor-menu-cta-container{
    display: flex;
    justify-content: end;
    align-items: center;
}

.TicketsInHomeComponentTvMonitor-menu-cta-container-company-search-box-selected{
    display: flex;
    justify-content: end;
    align-items: center;
    border: 5px #000000 solid;
}

.image-ticket-above-table-icons{
    width: 30px;
    height: 30px;
}

.bg-ticket-above-table-icons{
    border: none;
    border-radius: 36px;
    background-color: #ffffff;
    padding: 4px 10px;
    text-decoration: none;
    color: #2f4f4f;
    display: flex;
    align-items: center;
}

.Ticket-logo-container{
    display: flex;
    align-items: center;
}

.Ticket-logo-container img {
    height: 50px;
}

.ShowTicketByStaff-container-outer{
    background-color: rgba(0,0,0,0.85);
    width: 95%;
    height:  calc(100vh - 80px);
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShowTicketByStaff-container-inner{
    background-color: #ffffff;
    padding: 10px;
    width: 80%;
    height: 80%;
}

.ShowTicketByStaff-container-inner-search-box-and-list-container {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
}

.ShowTicketByStaff-container-inner-search-box-and-list {
    position: absolute;
    width: auto;
    z-index: 3;
    right: 0px;
    top: 40px;
    background-color: #353535;
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
    border: 1px #263238 solid;
}

.ShowTicketByStaff-container-inner-search-box {
    width: 400px;
}

.ShowTicketByStaff-container-list-single-row:hover td{
    background-color: red !important;
    cursor: pointer;
}

.TicketsInHomeComponentTvMonitor-company-details-container-inner{
    background-color: #000000;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding: 10px;
}

.TicketsInHomeComponentTvMonitor-company-details-container-inner{
    display: flex;
    justify-content: space-between;
}

.TicketsInHomeComponentTvMonitor-company-details-container-inner div{
    display: flex;
    flex-direction: column;
}

.ShowTicketByStaff-header-panel{
    display: flex;
    justify-content: space-between;
}

.marquee_img {
    width: 150px;
    object-fit: contain;
    margin: 0px 30px;
}

.admin-tv-monitor-left-side{
    width: 40%;
}

.admin-tv-monitor-right-side{
    width: 60%;
}

@media (max-width: 575.98px) {

    .TicketsInHomeComponentTvMonitor-header {
        overflow-x: scroll;
    }

    .TicketsInHomeComponentTvMonitor-header img {
        position: relative;
    }

    .TicketsInHomeComponentTvMonitor-header-right-section div {
        display: flex;
    }

    .TicketsInHomeComponentTvMonitor-header-right-section {
        flex-direction: unset;
    }

    .TicketsInHomeComponentTvMonitor-menu-cta-container button,
    .TicketsInHomeComponentTvMonitor-menu-cta-container a {
        min-width: 200px;
    }

}