@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');


.ReportIssue-root-container {
    font-family: 'Poppins', sans-serif;
    position: relative;
}

.ReportIssue-main-container {
    position: relative;
}

.ReportIssue-bottom-layer {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #e5e5e5;
    overflow: hidden;
}


.ReportIssue-bottom-layer-img-1 {
    position: absolute;
    left: -260px;
    top: 200px;
    height: 600px;
}

.ReportIssue-bottom-layer-img {
    position: absolute;
    right: -100px;
    height: 500px;
}

.ReportIssue-top-layer {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    padding-bottom: 100px;
}

.ReportIssue-top-layer-logo {
    width: 200px;
    object-fit: contain;
    margin: 20px;
}

.ReportIssue-top-layer-heading-h1 {
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 28px;
    color: #000;
    text-shadow: unset;
}

.ReportIssue-top-layer-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    width: 90%;
    margin: 0px auto;
    max-width: 900px;
    border-radius: 5px;
    box-shadow: 2px 2px 16px #454444;
}

.ReportIssue-top-layer-form button {
    border: none;
    outline: none;
    background-color: rgba(0, 38, 255, 0.9);
    color: #ffffff;
    padding: 5px 30px;
    cursor: pointer;
    border-radius: 5px;
}

.ReportIssue-top-layer-form textarea {
    width: 100% !important;
    height: 100px;
    border: 2px solid #95a395;
}

.ReportIssue-top-layer-form-input-wrapper,
.ReportIssue-top-layer-form-label-wrapper {
    width: 90%;
    max-width: 900px;
}

.ReportIssue-top-layer-heading-underline {
    width: 30px;
    height: 5px;
    border-radius: 36px;
    background-color: #d72323;
    margin: 0px auto;
}

.attach-images-container,
.form-container {
    width: 90%;
}


.image-attach {
    width: 24px;
    height: 24px;
}

.mobile-number-input {
    border: 2px solid #95a395;
}

.PhoneInputInput {
    flex: unset;
    min-width: 0;
    width: 40% !important;
    border: 2px solid #95a395;
}

.ReportIssue-previous-list-single-item {
    display: flex;
    flex-direction: row;
    width: 98%;
    position: relative;
    box-shadow: 1px 1px 4px #aeaeae;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #f3f3f3;
    margin-top: 8px;
    border: 1px solid #999999;
}

.ReportIssue-single-item-current-status {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: flex-end;
}

.ReportIssue-single-item-left-child {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ReportIssue-single-item-left-child-top-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ReportIssue-single-item-left-child-top-panel-child {
    display: flex;
    flex-direction: column;
}



.ReportIssue-single-item-left-child-bottom-panel {
    display: flex;
    flex-direction: column;
}

.ReportIssue-single-item-view-details-link {
    background-color: #468f5c;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 44px;
    text-decoration: none;
}

.ReportIssue-single-item-view-details-link:hover {
    border: 1px #468f5c solid;
    background-color: #ffffff;
    color: #468f5c;
    text-decoration: none;
}

.Go-to-reply-single-item-view-details-link {
    background-color: #828784;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    border: 1px solid #828784;
}

.Go-to-reply-single-item-view-details-link:hover {
    background-color: #ffffff;
    color: #000000;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    border: 1px solid #828784;
}

.Previous-Tickets-Container {
    padding-bottom: 30px;
    height: 90vh !important;
    overflow: scroll;
}

.Previous-Tickets-Container-label {
    color: gray;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 8px;
}

.Previous-Tickets-Container-value {
    color: #000000;
    font-size: 18px;
}

.ReportIssue-view-closed-tickets-link {
    background-color: #10311a;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 44px;
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    border: 1px #10311a solid;
}

.ReportIssue-view-closed-tickets-link:hover {
    border: 1px #10311a solid;
    background-color: #ffffff;
    color: #000;
}

.ReportIssue-chip-container {
    width: 90%;
    max-width: 900px;
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
}

.ReportIssue-usual-issue-chip-bg {
    border: 1px #32395f solid;
    background-color: #32395f;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 44px;
    text-decoration: none;
    margin: 2px 4px;
    display: flex;
}


.ReportIssue-usual-issue-chip-bg:hover {
    border: 1px #32395f solid;
    background-color: #ffffff;
    color: #32395f;
    cursor: pointer;
}

.ReportIssue_selected_chip_bg {
    background-color: #ffffff;
    width: 22px;
    height: 22px;
    border-radius: 36px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.ReportIssue-max-limit-text {
    display: block;
    font-size: 14px;
    text-align: right;
    width: 90%;
    max-width: 900px;
    color: #747171;
    font-weight: 600;
}

.ReportIssue-name-support-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Previous-Tickets-Container-company-name {
    color: #000000;
    font-weight: 500;
    font-size: 18px;
}

.ReportIssue-error-text-before-sending {
    color: #ff0000;
}

.ReportIssue_borderless_input {
    border: none;
    outline: none;
    border-bottom: 2px solid #000000;
}

.ReportIssue-by-cubix-staff-single-item {
    display: flex;
    flex-direction: column;
}

.ReportIssue-by-cubix-staff-single-item-view-details {
    display: flex;
    justify-content: end;
    padding: 5px;
}

.ReportIssue-single-item-chatbox-mini-box {
    background-color: #ffffff;
    padding: 5px 0px 10px 0px;
    box-shadow: inset 1px 1px 9px #d3c1c1;
    width: 80%;
}

.ReportIssue-single-item-chatbox-label {
    background-color: #d8e3fe;
    color: #4b86b9;
    display: inline;
    padding: 2px 4px;
}

.ReportIssue-single-item-chatbox-ticket-description {
    background-color: #ffffff;
    color: #719e7e;
    padding: 2px 4px;
    font-weight: 400;
}

.ReportIssue-single-item-chatbox-label-support-admin {
    background-color: #ffffff;
    color: #719e7e;
    padding: 2px 4px;
    font-weight: 400;
}

.ReportIssue-single-item-chatbox-button-reply {
    background-color: #d8e3fe;
    color: #4b86b9;
    display: inline;
    padding: 2px 4px;
}

.ReportIssue-single-item-chatbox-mini-and-reply {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReportIssue-chat-reply-bg {
    background-color: #000 !important;
}

.ReportIssue-ticket-person-name {
    display: flex;
    flex-direction: column;
}

.ReportIssue-logo-and-notification-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReportIssue-alerts-container-bg {
    background-color: #d9534f;
    border-radius: 5px;
    padding: 10px;
    color: #f0ffff;
    display: flex;
    align-items: center;
}

.ReportIssue-alerts-container-bg img {
    height: 55px;
}

.google-review-container {
    background-color: #ffffff;
    color: #000;
}

.ReportIssue-review-button {
    text-decoration: none;
    background-color: #000000;
    color: #ffffff;
    border-radius: 36px;
    padding: 5px 10px 5px 5px;
    border: 1px solid #000000;
}


.ReportIssue-review-button:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #000000;
    border-radius: 36px;
    border: 1px solid #000000;
    padding: 5px 10px 5px 5px;
}

.ReportIssue-google-icon {
    height: 30px !important;
}

.blocked-customer-view {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

@media (min-width: 1400px) {}

@media (min-width: 1200px) and (max-width: 1399.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}


@media (min-width:576px) and (max-width: 767.98px) {

    .ReportIssue-bottom-layer-img {
        display: none;
    }
}


@media (max-width: 575.98px) {

    .ReportIssue-bottom-layer-img-1 {
        position: absolute;
        left: -260px;
        top: 200px;
        height: 460px;
    }

    .ReportIssue-bottom-layer-img {
        display: none;
    }

    .ReportIssue-top-layer-heading-h1 {
        font-size: 20px;
    }

    .ReportIssue-top-layer-heading p {
        font-size: 16px;
    }

    .PhoneInputInput {
        flex: unset;
        min-width: 0;
        width: 75% !important;
        border: 2px solid #95a395;
    }
}