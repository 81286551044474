@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


.AllTasks-root-container{
   background-color: #4C66AA;
   height: 100vh;
   overflow-y: scroll;
   font-family: 'Montserrat', sans-serif;
}
.AllTasks-root-container-top-panel{
    display: flex;
    padding-top: 10px;
    height: 100px;
}

.AllTasks-root-container-top-panel img{
    position: relative;
    left: -30px;
    height: 85px;
}

.AllTasks-root-container-top-panel h4{
    color: #ffffff;
}

.AllTasks-left-right-container{
    display: flex;
    width: 100%;
    height: calc(100vh - 160px);
}

.AllTasks-left-child{
    background-color: #020202;
    width: 50%;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    padding-top: 15px;
}

.AllTasks-right-child{
    background-color: #2c2c2c;
    width: 50%;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    padding-top: 15px;
}

.AllTasks-right-child-list-container{
   padding: 20px;
}

.AllTasks-right-child-list-single-item {
    position: relative;
    background-color: #000000;
    padding: 15px;
}

.AllTasks-right-child-list-single-item>img {
    width: 30px;
    position: absolute;
    left: -14px;
}


.AllTasks-right-child-list-single-item-date-mark-container
{
    margin-left: 5px;
}

.AllTasks-right-child-list-single-item-description {
    font-size: 19px;
    font-family: 'Lato', sans-serif;
    margin-top: 20px;
}

.AllTasks-right-child-list-single-item-date-mark-container {
    display: flex;
}

.AllTasks-right-child-list-single-item-date-mark-container label {
    color: #508d84;
    font-weight: 500;
}

.AllTasks-right-child-list-single-item-mark-complete {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    margin-left: auto;
}

.AllTasks-right-child-list-single-item-full-description-container label {
    font-size: 14px;
    font-weight: 600;
    color: #706f6f;
}

.AllTasks-right-child-list-single-item-full-description-container p {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.AllTasks-right-child-list-single-assign-details,
.AllTasks-right-child-list-single-time-details {
    display: flex;
    justify-content: space-between;
}

.AllTasks-right-child-list-single-item-full-description-container {
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 20px;
}

.AllTasks-task-number-bg {
    border-radius: 36px;
    padding: 0px 20px;
    background-color: #ffffff;
    border: 2px solid #90CE7A;
}

@media (max-width: 575.98px){

    .AllTasks-right-child-list-single-item-full-description-container p {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
    }
    .AllTasks-root-container-top-panel img {
        position: relative;
        left: -15px;
        height: 60px;
    }

    .AllTasks-root-container-top-panel {
        display: flex;
        padding-top: unset;
        height: unset;
        align-items: center;
    }

    .AllTasks-root-container-top-panel h4 {
        font-size: 20px;
    }
    .AllTasks-right-child-list-single-item-full-description-container p {
        font-size: 16px;
        font-weight: 400;
    }
    .AllTasks-right-child-list-single-assign-details, .AllTasks-right-child-list-single-time-details {
        display: flex;
        flex-wrap: wrap;
    }
}