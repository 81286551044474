.Admin-Companies-root-container{
    font-family: 'Poppins', sans-serif;
}
.AdminHome-companies-list{
    list-style: none;
}
.AdminHome-companies-list .card-item{
    background-color: aliceblue;
}
.AdminHome-companies-list .card-item-name{
    color: #19185a;
    padding: 10px 20px;
    font-size: 20px;
}
.AdminHome-companies-list .card-item-bottom{
    background-color: rgb(66, 86, 104);
    color: #ffffff;
    margin: 10px 20px;
    padding: 5px;
}

.AdminHome-red-button{
    background-color: red;
    color: #ffffff;
    border: none;
}

.AdminHome-green-button{
    background-color: green;
    color: #ffffff;
    border: none;
}
