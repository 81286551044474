@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.CreateTicketPopupContainer-root-container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
    font-family: 'Montserrat', sans-serif;
}

.CreateTicketPopupContainer-inner-card {
    position: absolute;
    width: 50%;
    height: 75vh;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 30px 20px 30px 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.CreateTicketPopupContainer-inner-card input {
    background-color: #F3F3F3;
    border: 1px solid #838383;
    min-height: 35px;
    padding: 0px 10px;
}

.CreateTicketPopupContainer-Expected-Completion-Date-Time-container {
    display: flex;
    align-items: center;
}

.CreateTicketPopupContainer-pick-bg {
    background-color: #3A3535;
    padding: 5px 15px;
    color: #ffffff;
}

.CreateTicketPopupContainer-side-clip-image {
    position: absolute;
    width: 70px;
    top: 70px;
    left: 23%;
    z-index: 10;
}

.CreateTicketPopupContainer-cta-pick-date,
.CreateTicketPopupContainer-cta-pick-time {
    cursor: pointer;
}


.CreateTicketPopupContainer-cancel-save-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.CreateTicketPopupContainer-cancel-button,
.CreateTicketPopupContainer-completed-button,
.CreateTicketPopupContainer-save-button {
    border: none;
    outline: none;
    padding: 5px 10px;
}

.CreateTicketPopupContainer-cancel-button {
    background-color: #838383;
    color: #ffffff;
    padding: 5px 20px;
}

.CreateTicketPopupContainer-save-button {
    background-color: #5472BF;
    color: #ffffff;
    padding: 5px 20px;
}


.CreateTicketPopupContainer-company-search-box {
    background-color: #ffffff !important;
    border: 1px solid #0c84a8 !important;
    width: 100%;
    padding: 2px 5px 2px 10px;
    position: sticky !important;
    top: 0px !important;
}

.CreateTicketPopupContainer-completed-button{
    background-color: rgb(78 233 90);
}

@media (max-width: 575.98px) {
    .CreateTaskPopupContainer-dropdown-picker {
        background-color: #838383;
        border: 1px solid gainsboro;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        color: #ffffff;
    }

    .CreateTicketPopupContainer-side-clip-image {
        position: absolute;
        width: 30px;
        top: 70px;
        left: 10%;
        z-index: 10;
    }

    .CreateTicketPopupContainer-inner-card {
        width: 75%;
        padding: 30px 20px 30px 25px;
    }

    .CreateTicketPopupContainer-cancel-save-container {
        display: flex;
        justify-content: unset;
        flex-direction: column;
    }

    .CreateTicketPopupContainer-cancel-save-container button {
        margin: 10px 0px !important;
    }

}