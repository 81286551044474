@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


.AllTickets-root-container {
    background-color: #4b587a;
    height: 100vh;
    overflow-y: scroll;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 50px;
}

.AllTickets-root-container-top-panel {
    display: flex;
    padding-top: 10px;
    height: 100px;
}

.AllTickets-root-container-top-panel img {
    position: relative;
    left: -30px;
    height: 85px;
}

.AllTickets-root-container-top-panel h4 {
    color: #ffffff;
}

.AllTickets-left-right-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 160px);
}

.AllTickets-left-child {
    background-color: #020202;
    width: 50%;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    padding-top: 15px;
}

.AllTickets-right-child {
    background-color: #181818;
    width: 50%;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    padding-top: 15px;
}

.AllTickets-left-child-list-container {
    margin: 20px 20px 20px 20px;
}

.AllTickets-right-child-list-container {
    padding: 20px;
}

.AllTickets-right-child-list-single-item {
    position: relative;
    background-color: #000000;
    padding: 15px;
}

.AllTickets-right-child-list-single-item-description {
    font-size: 19px;
    margin-top: 20px;
}

.AllTickets-right-child-list-single-item-date-mark-container {
    display: flex;
    margin-left: 20px;
}

.AllTickets-right-child-list-single-item-date-mark-container label {
    color: #508d84;
    font-weight: 500;
}

.AllTickets-right-child-list-single-item-mark-complete {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    margin-left: auto;
}

.AllTickets-left-child h5 {
    color: #ffffff;
}


.AllTickets-right-child-list-single-item-full-description-container label {
    font-size: 14px;
    font-weight: 600;
    color: #bfbfbf;
}

.AllTickets-right-child-list-single-item-full-description-container p {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.AllTickets-right-child-list-single-assign-details,
.AllTickets-right-child-list-single-time-details {
    display: flex;
    justify-content: space-between;
}

.AllTickets-right-child-list-single-item-full-description-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}
.AllTickets-label-bg {
    border-radius: 36px;
    padding: 0px 20px;
    background-color: #ffffff;
    border: 2px solid #ad987b;
    color: #000000 !important;
}

.AllTickets-closed-tickets-from-customer {
    text-decoration: none;
    padding: 8px 12px;
    background-color: ghostwhite;
}

.AllTickets-from-customer-panel-container-admin-view {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.AllTickets-from-customer-company-name-and-view-details{
    display: flex;
    justify-content: space-between;
}
.AllTickets-from-customer-company-name-and-view-details a {
    background-color: #468f5c;
    color: #ffffff;
    padding:  5px 10px;
    border-radius: 44px;
    text-decoration: none;
    width: 125px;
    display: block;
}
.AllTickets-from-customer-company-name-and-view-details a:hover {
    background-color: #ffffff;
    color: #468f5c;
    border: 1px solid #468f5c;
    padding: 5px 10px;  
    border-radius: 44px;
    text-decoration: none;
    width: 125px;
}

.AllTickets-single-item-left-child-top-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AllTickets-closed-tickets-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    width: 95%;
    margin: 0px auto;
    border-radius: 5px;
    box-shadow: 2px 2px 16px #454444;
    padding-bottom: 10px;
    height: calc(100vh - 230px) !important;
    overflow: scroll;
}


.AllTickets-closed-tickets-table-container table{
    width: 100%;
}

.AllTickets-closed-tickets-table-admin-view tr th{
    background-color: #468f5c;
    color: #ffffff;
    padding: 5px 10px;
    vertical-align: baseline;
}

.AllTickets-closed-tickets-table-admin-view tbody tr td{
    padding: 5px 10px;
    vertical-align: top;
}

.AllTickets-closed-tickets-table-company-name{
    color: #000000;
    font-weight: 500;
    font-size: 15px;
}

.AllTickets-closed-tickets-table-contact-number{
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 8px;
}

.AllTickets-closed-tickets-table-date{
    color: #000;
    font-weight: 500;
    font-size: 15px;
}

.AllTickets-closed-tickets-table-admin-view tbody tr:nth-child(even){
    background-color: #e0ffed;
  }

  .AllTickets-closed-tickets-table-admin-view-details-button {
    background-color: gold;
    color: black;
    padding: 0px 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    margin-top: 10px;
}
  
.AllTickets-closed-tickets-table-admin-view-details-button:hover {
    background-color: blue;
    color: #ffffff;
}
@media (max-width:575.98px) {

    .AllTickets-right-child-list-single-item-full-description-container p {
        font-size: 16px;
        font-weight: 400;
    }

    .AllTickets-right-child-list-single-assign-details, .AllTickets-right-child-list-single-time-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .AllTickets-root-container-top-panel {
        display: flex;
        padding-top: 10px;
        height: unset;
        align-items: center;
    }
    .AllTickets-root-container-top-panel h4 {
        font-size: 18px;
    }

    .AllTickets-root-container-top-panel h4 {
        color: #ffffff;
        font-size: 18px;
    }
    .AllTickets-right-child-list-single-item-full-description-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
    }
    .AllTickets-right-child-list-single-item-date-mark-container {
        display: flex;
        margin-left: unset;
    }
    .AllTickets-right-child-list-container {
        padding: 0px 10px;
    }

    .AllTickets-single-item-left-child-top-panel {
        display: flex;
        flex-direction: column;
    }

    .AllTickets-single-item-left-child-top-panel div{
        margin-bottom: 10px;
    }
}