.CustomerPreviousTickets-root-container{
    background: #39507C !important;
    width: 100%;
    max-height: calc(100vh - 66px);
}

.CustomerPreviousTickets-root-container input{
    background-color: ghostwhite;
    padding: 10px 15px;
    width: 100%;
    border: none;
    border: 1px solid #bebebe;
    font-family: 'Poppins', sans-serif;
}

.CustomerPreviousTickets-root-container input:nth-child(1),
.CustomerPreviousTickets-root-container input:nth-child(2){
    margin-right: 5%;
}

.CustomerPreviousTickets-attach-image-bg {
    background-color: #39507C;
    border: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: #E5E5E5;
    padding: 5px 20px;
}


.CustomerPreviousTickets-create-button {
    display: block;
    background-color: #F4AC20;
    border: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    padding: 5px 20px;
    margin-left: auto;
    margin-right: auto;
}

.CustomerPreviousTickets-attach-img-note{
    color: #ffffff;
    font-weight: 300;
}


.CustomerPreviousTickets-details-about-issue{
    background-color: ghostwhite;
    padding: 10px 15px;
    width: 100%;
    border: none;
    border: 1px solid #bebebe;
    font-family: 'Poppins', sans-serif;
}

.CustomerPreviousTickets-left-right-container{
    display: flex;
    width: 100%;
    height: calc(100vh - 66px);
}
.CustomerPreviousTickets-left-container{
    width: 30%;
    height: calc(100vh - 66px);
    overflow-y: scroll;
    background-color: #c8cce3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.CustomerPreviousTickets-left-container::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.CustomerPreviousTickets-left-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.CustomerPreviousTickets-right-container{
    width: 70%;
    height: calc(100vh - 66px);
    position: relative;
    overflow-y: scroll;
}


.CustomerPreviousTickets-list-container{
    list-style: none;
    padding: 0px !important;
    margin-bottom: 0px;
}

.CustomerPreviousTickets-list-container li{
    border-bottom: 1px solid #39507C;
}

.CustomerPreviousTickets-list-container li label{
    cursor: pointer;
}

/* .CustomerPreviousTickets-list-container li:last-child{
    border-bottom:none;
} */

.CustomerPreviousTickets-list-container .not-selected{
    background-color: linear-gradient(rgba(84, 114, 191, 0.62), rgba(84, 114, 191,1));
    color: #000;
    padding: 10px 20px ;
    cursor: pointer;
    border-right: 6px solid #282d6e;
}

.CustomerPreviousTickets-list-container .selected{
    background-color: #39507C !important;
    color: #ffffff;
    padding: 10px 20px ;
    border: 4px solid #282d6e;
    border-left: 15px solid #6c6f9d;
    border-right: none;
}


.CustomerPreviousTickets-list-container .not-selected .CustomerPreviousTickets-list-ticket-number-date{
    color: #adacac;
}

.CustomerPreviousTickets-list-container li > div{
    display: flex;
    flex-direction: column;
}

.CustomerPreviousTickets-list-container label{
    font-family: 'Poppins', sans-serif;
}
.CustomerPreviousTickets-list-container p{
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
}
.CustomerPreviousTickets-list-ticket-number{
    position: relative;
}

.CustomerPreviousTickets-list-ticket-number div:nth-child(2) label{
    font-size: 14px;
}
.CustomerPreviousTickets-list-ticket-number-date{
    background-color: #39507C;
    position: absolute;
    border-radius: 20px;
    right: 0px;
    font-size: 14px !important;
    padding: 2px 10px;
    font-weight: 300;
}

.CustomerPreviousTickets-img-container img{
    width: 300px;
    height: 150px;
    margin: 20px;
    object-fit: cover;
}

.CustomerPreviousTickets-full-details{
    padding: 0px 30px 30px 30px;
}

.CustomerPreviousTickets-full-details div label{
    width: 16%;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: #dfc2c2;
}

.CustomerPreviousTickets-full-details div span{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}

.CustomerPreviousTickets-full-details-image label{
    padding: 0px 30px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}

.CustomerPreviousTickets-right-container hr {
    opacity: 1;
}

.CustomerPreviousTickets-right-container-status-board{
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    margin: 30px;
}

.CustomerPreviousTickets-right-container-status-board label{
    background-color: #555555;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 300;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;    
}

.CustomerPreviousTickets-right-container-status-board span{
    background-color: #5472BF;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 14px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.CustomerPreviousTickets-no-image-uploaded{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #d9d1d1;
    padding: 20px 30px 0px 30px;
}

.CustomerPreviousTickets-left-pagination-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
    border-right: 6px solid #282d6e;
}

.CustomerPreviousTickets-left-pagination-container label{
    color: #4e4545;
    padding: 0px 20px;
    font-family: 'Poppins', sans-serif;
}

.CustomerPreviousTickets-left-pagination-container button{
    color: #ffffff;
    padding: 5px 10px;
    background-color: #4a4747;
    border: none;
    font-family: 'Poppins', sans-serif;
}

.CustomerPreviousTickets-left-spacer-div{
    width: 100%;
    flex-grow: 1;
    border-right: 6px solid #282d6e;
}

.AdminHome-left-container{
    display: flex;
    flex-direction: column;
}