.FilterPopupContainer-root-container{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
}

.FilterPopupContainer-inner-card{
    position: absolute;
    width: 50%;
    height: 75vh;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 30px 20px 30px 20px;
    top: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.FilterPopupContainer-inner-card input{
    background-color: #F3F3F3;
    border: 1px solid #838383;
}

.FilterPopupContainer-Expected-Completion-Date-Time-container{
    display: flex;
    align-items: center;
}

.FilterPopupContainer-pick-bg{
    background-color: #3A3535;
    padding: 5px 15px;
    color: #ffffff;
}

.FilterPopupContainer-side-clip-image{
    position: absolute;
    width: 70px;
    top: 70px;
    left: 23%;
    z-index: 10;
}

.FilterPopupContainer-cta-pick-date ,
.FilterPopupContainer-cta-pick-time{
    cursor: pointer;
}


.FilterPopupContainer-cancel-save-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.FilterPopupContainer-cancel-button,
.FilterPopupContainer-save-button{
    border: none;
    outline: none;
    padding: 5px 10px;
}

.FilterPopupContainer-cancel-button{
    background-color: #838383;
    color: #ffffff;
    padding: 5px 20px;
}

.FilterPopupContainer-save-button {
    background-color: #5472BF;
    color: #ffffff;
    padding: 5px 20px;
}


.FilterPopupContainer-company-search-box {
    background-color: #ffffff !important;
    border: 1px solid #0c84a8 !important;
    width: 100%;
    padding: 2px 5px 2px 10px;
    position: sticky !important;
    top: 0px !important;
}

.FilterPopupContainer-inner-card h6 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}

.FilterPopupContainer-header-panel{
    display: flex;
}

.FilterPopupContainer-header-panel img {
    height: 18px;
}

.FilterPopupContainer-select-staff-name-container{
    position: relative;
}

.FilterPopupContainer-ticket-status {
    display: flex;
    justify-content: unset; 
}

@media (max-width: 575.98px){
    .CreateTaskPopupContainer-dropdown-picker {
        background-color: #838383;
        border: 1px solid gainsboro;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        color: #ffffff;
    }
    .FilterPopupContainer-side-clip-image {
        position: absolute;
        width: 30px;
        top: 70px;
        left: 10%;
        z-index: 10;
    }
    .FilterPopupContainer-inner-card {
        width: 75%;
        padding: 30px 20px 30px 25px;
    }

    .FilterPopupContainer-ticket-status div{
        margin: 6px 0px !important;
    }
}