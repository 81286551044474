@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');

.LoginUser-root-container {
    display: flex;
}

.LoginUser-cubix-logo {
    position: absolute;
    margin: 40px 0px 20px 40px;
}

.LoginUser-left-container {
    height: 100vh;
}

.LoginUser-left-container .LoginUser-side-banner-img {
    height: 100vh;
    object-fit: cover;
}

.LoginUser-top-layer-container div p {

    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    text-align: center;
}

h1 {
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    font-weight: 600;
    text-shadow: 0 0 3px #525252;
    font-size: 28px;
}

h5 {
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
}

.LoginUser-bg-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LoginUser-bg-image-container {
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.LoginUser-top-layer-container {
    background: linear-gradient(rgba(84, 114, 191, 0.62), rgba(84, 114, 191, 1));
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.LoginUser-form-container {
    display: flex;
    flex-direction: column;
    width: 26vw;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #767676;
    padding: 20px 30px 40px 30px;
}

.LoginUser-form-container label {
    font-family: 'Poppins', sans-serif;
}

.LoginUser-form-container input {
    background-color: ghostwhite;
    padding: 10px 15px;
    border: none;
    border: 1px solid #bebebe;
}


.LoginUser-login-button {
    background-color: #1094A9;
    border: none;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-top: 20px;
    padding: 5px 0px;
    color: #ffffff;
}

.LoginUser-top-dot {
    width: 20px;
    position: absolute;
    bottom: 70px;
    right: 20px;
}

.LoginUser-bottom-dot {
    width: 90px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.LoginUser-side-dot-panel {
    width: 50px;
    position: absolute;
    bottom: 150px;
    right: 30px;
}

.LoginUser_register_now_panel {
    background-color: #ffffff;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
}

.LoginUser_register_now_panel label {
    font-family: 'Poppins', sans-serif;
}

.LoginUser_register_now_panel a {
    background-color: #fbda36;
    padding: 10px 15px;
    text-decoration: none;
    color: #3d3056;
    border-radius: 5px;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
}

.LoginUser-registration-error-container {
    text-align: center;
    color: red;
    font-family: 'Poppins', sans-serif;
    padding: 20px 5px 5px 5px;
}

@media (max-width: 575.98px) {
    .hidden-in-mobile {
        display: none !important;
    }

    .LoginUser-form-container {
        width: 75%;
    }

    .LoginUser-top-layer-container {
        justify-content: unset;
        padding: 30px 0px 10px 0px;
    }
    .LoginUser-top-layer-container-heading {
        font-weight: 600;
        font-size: 20px;
    }
    .LoginUser-top-layer-container div p {
        padding: 0px 30px;
    }
}