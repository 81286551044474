@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.DailyTasksInHomeComponent-root-container {

    font-family: 'Montserrat', sans-serif;
    background-color: #708090;
}

.DailyTasksInHomeComponent-header {
    position: relative;
    display: flex;
    align-items: center;
}

.DailyTasksInHomeComponent-header img {
    position: relative;
    left: -30px;
}

.DailyTasksInHomeComponent-header-right-section {
    display: flex;
    flex-direction: column;
    color: #ffffff;
}

.DailyTasksInHomeComponent-header-right-section div {
    display: flex;
}

.DailyTasksInHomeComponent-header-right-section button {
    border: none;
    outline: none;
    border-radius: 5px;
    color: #263238;
    background-color: #CBD3EA;
    padding: 4px 12px;
}

.DailyTasksInHomeComponent-header-right-section a {
    border: none;
    outline: none;
    border-radius: 5px;
    color: #263238;
    background-color: #CBD3EA;
    padding: 4px 12px;
    text-decoration: none;
}

.DailyTasksInHomeComponent-task-list-container {
    width: 100%;
    margin-top: 20px;
}

.DailyTasksInHomeComponent-left-right-container {
    display: flex;
    height: calc(100vh - 80px);
}

.DailyTasksInHomeComponent-inner-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.SideSmallDrawer-root-container {
    width: 5%;
}

@media (max-width:575.98px) {
    .DailyTasksInHomeComponent-header img {
        position: relative;
        left: -15px;
        height: 60px;
    }

    .DailyTasksInHomeComponent-header-right-section button {
        font-size: 14px;
    }

    .DailyTaskStaffCard-task-list-single-item-full-description-container p {
        font-size: 16px;
        font-weight: 400;
    }

    .DailyTaskStaffCard-task-list-single-assign-details,
    .DailyTaskStaffCard-task-list-single-time-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .DailyTaskStaffCard-task-list-single-item-date-mark-container {
        margin-left: unset;
    }

    .DailyTaskStaffCard-root-container {
        margin: 10px 5%;
        padding: 15px 0px;
    }

    .DailyTasksInHomeComponent-task-list-container {
        margin-top: 15px;
    }
}