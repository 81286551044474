.DailyTaskStaffCard-root-container {
    background-color: #3d5c32;
    border-radius: 5px;
    color: #ffffff;
    padding: 15px 0px;
    overflow-x: scroll;
    width: 98%;
    height:calc(100vh - 240px);
}

.DailyTaskStaffCard-staff-name-container {
    padding: 10px;
}

.DailyTaskStaffCard-staff-name-container h6 {
    font-size: 18px;
    color: #000000;
    margin-left: 34px;
}

.DailyTaskStaffCard-task-list-single-item {
    position: relative;
    background-color: #73b75b;
    padding: 15px;
}

.DailyTaskStaffCard-table {
    border-collapse: collapse;
}

.DailyTaskStaffCard-table tbody td {
    border-bottom: 1px solid rgb(56 135 80) !important;
}

.DailyTaskStaffCard-table thead tr th div {
    padding: 5px 15px !important;
}


.DailyTaskStaffCard-table tbody tr td div {
    padding: 5px 15px !important;
}

.DailyTaskStaffCard-table-description-column,
.DailyTaskStaffCard-table-company-name-column,
.DailyTaskStaffCard-table-remarks-column{
    width: 200px;
}

.DailyTaskStaffCard-task-list-single-item>img {
    width: 30px;
    position: absolute;
    left: -14px;
}

.DailyTaskStaffCard-task-list-single-item-date-mark-container {
    display: flex;
    margin-left: 15px;
}

.DailyTaskStaffCard-task-list-single-item-date-mark-container label {
    color: #000080;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.DailyTaskStaffCard-task-list-single-item-mark-complete {
    display: flex;
    align-items: center;
    background-color: #2a420e;
    color: #eadede;
    margin-left: auto;
    border: 2px solid #90CE7A;
}

.DailyTaskStaffCard-task-list-single-item-full-description-container {
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 20px;
}

.DailyTaskStaffCard-task-list-single-item-full-description-container label {
    font-size: 14px;
    font-weight: 600;
    color: #b8ff9f;
}

.DailyTaskStaffCard-task-list-single-item-full-description-container p {
    font-size: 18px;
    font-weight: 600;
}

.DailyTaskStaffCard-task-list-single-assign-details,
.DailyTaskStaffCard-task-list-single-time-details {
    display: flex;
    justify-content: space-between;
}

.DailyTaskStaffCard-task-number-bg {
    border-radius: 36px;
    padding: 0px 20px;
    background-color: #426436;
    border: 2px solid #90CE7A;
    color: #ffffff;
    font-weight: 600;
}

@media (max-width:575.98px) {

    .DailyTaskStaffCard-task-list-single-item-full-description-container p {
        font-size: 16px;
        font-weight: 400;
    }

    .DailyTaskStaffCard-task-list-single-assign-details,
    .DailyTaskStaffCard-task-list-single-time-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .DailyTaskStaffCard-task-list-single-item-date-mark-container {
        margin-left: unset;
    }

    .DailyTaskStaffCard-task-list-single-item-date-mark-container span {
        display: flex;
    }

    .DailyTasksInHomeComponent-task-list-container {
        margin-top: 25px;
    }

    .DailyTaskStaffCard-task-list-single-item-mark-complete {
        width: 125px;
        font-size: 14px;
    }

    .DailyTaskStaffCard-task-list-single-item-date-mark-container label {
        font-size: 14px;
    }

    .DailyTaskStaffCard-task-number-bg {
        padding: 0px 10px;
    }
}