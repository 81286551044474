.TicketSingleCard-root-container {
    margin-bottom: 30px;
    border-radius: 5px;
    color: #ffffff;
    height: 100%;
    overflow-y: scroll;
}

.TicketSingleCard-list-single-item-mark-complete {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    margin-left: auto;
    border: 2px solid #fbfbfb;
}

.TicketSingleCard-list-container-single-item {
    margin-top: 10px;
    background-color: #ffffff;
}

.TicketSingleCard-list-container-single-item-direct-from-customer {
    margin-top: 10px;
    background-color: #eff5f9;
    margin: 5px 0px;
    border-bottom: 5px #ffffff solid;
}

.TicketSingleCard-list-container-single-item-direct-from-customer td {
    border-right: 5px #ffffff solid;
    padding: 8px 6px;
}


.TicketSingleCard-table-description-column p,
.TicketSingleCard-table-company-name-column p {
    overflow: hidden;
}

.TicketSingleCard-list-all-tickets-in-a-date-single-container {
    background-color: #765a32;
    border-radius: 5px;
    padding: 15px 20px;
    color: #FAF3F3;
    margin-top: 10px;
}

.TicketSingleCard-list-all-tickets-in-a-date-single-container-divider {
    width: 1px;
    height: 10px;
    border-right: 3px solid #7FA4B6;
    margin: 0px 10px;
}

.TicketSingleCard-list-single-item-date-bubble {
    background-color: #37474F;
    color: #ffffff;
    border-radius: 36px;
    padding: 2px 15px;
    font-size: 14px;
}

.TicketSingleCard-list-single-item-full-description-container label {
    font-size: 14px;
    font-weight: 600;
    color: #e5c496;
}

.TicketSingleCard-list-single-item-full-description-container p {
    font-size: 18px;
    font-weight: 600;
}

.TicketSingleCard-list-single-assign-details,
.TicketSingleCard-list-single-time-details {
    display: flex;
    justify-content: space-between;
}


.TicketSingleCard-ticket-number-bg {
    border-radius: 36px;
    padding: 0px 20px;
    background-color: #4c3a21;
    border: 2px solid #ad987b;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
}

.TicketSingleCard-ticket-number-text {
    color: #000;
}


.TicketSingleCard-table {
    overflow-y: hidden;
    width: 100%;
}

.TicketSingleCard-table thead {
    position: sticky;
    top: 0px;
    background-color:#f1f1f1;
    box-shadow: -24px 1px 22px #9a9a9a;
}



.TicketSingleCard-table thead tr th div {
    padding: 5px 15px !important;
    color: #000;
}




.TicketSingleCard-table-company-name-column,
.TicketSingleCard-table-remarks-column {
    width: 200px;
}

.TicketSingleCard-table-description-column {
    width: 400px;
}

.from-customer-value-text {
    color: #000000;
}

.unallocated-ticket-left-border {
    /* border: 1px #c3bebe solid;
    box-shadow: 2px 2px 6px #adadad; */
    border-left: 5px red dotted;
}


.allocated-ticket-left-border {
    /* border: 1px #c3bebe solid;
    box-shadow: 2px 2px 6px #adadad; */
    border-left: 10px green double;
}

.image-ticket-action {
    width: 30px;
    height: 30px;
    background-color: #dada99;
    border-radius: 4px;
    padding: 2px;
}

.Ticket-action-container {
    display: flex;
}

.Ticket-Change-Status {
    cursor: pointer;
}

.TicketSingleCard-table-description-column p:hover,
.TicketSingleCard-table-company-name-column p:hover {
    cursor: pointer;
}

.TicketSingleCard-opened-bg {
    background-color: #ffa500;
}

.TicketSingleCard-assigned-bg {
    background-color: #008000;
}

.TicketSingleCard-escalated-bg {
    background-color: red;
}

.TicketSingleCard-status-paragraph-text-color {
    color: #ffffff;
}

.TicketSingleCard_support_person_name_paragraph {
    background-color: #0d0d0d4f;
    color: #ffffff;
    padding: 5px 10px;
    margin-bottom: 0px;
}

.TicketSingleCard_support_ticket_id_badge {
    padding: 0px 10px;
    color: #000000;
    border: 1px solid #000000;
}

.TicketSingleCard-company-code-chip{
    background-color: #765a32;
    color: #ffffff;
    padding: 0px 5px;
    font-size: 12px;
}

@media(max-width: 575.98px) {
    .TicketSingleCard-list-single-item-mark-complete {
        width: 125px;
        font-size: 14px;
    }

    .TicketSingleCard-ticket-list-single-item-date-mark-container label {
        font-size: 14px;
    }

    .TicketSingleCard-list-single-item-full-description-container p {
        font-size: 16px;
        font-weight: 400;
    }

    .TicketSingleCard-list-all-tickets-in-a-date-single-container {
        padding: 15px 10px;
    }

    .TicketSingleCard-ticket-number-bg {
        padding: 0px 10px;
    }
}