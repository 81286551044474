.CompletionPopup-root-container{
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CompletionPopup-inner-card{
    position: absolute;
    width: 50%;
    height: 75vh;
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding: 30px 20px 30px 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.CompletionPopup-inner-card input{
    background-color: #F3F3F3;
    border: 1px solid #838383;
}

.CompletionPopup-Expected-Completion-Date-Time-container{
    display: flex;
    align-items: center;
}

.CompletionPopup-pick-bg{
    background-color: #3A3535;
    padding: 5px 15px;
    color: #ffffff;
}

.CompletionPopup-side-clip-image{
    position: absolute;
    width: 70px;
    top: 70px;
    left: 23%;
    z-index: 10;
}

.CompletionPopup-cta-pick-date ,
.CompletionPopup-cta-pick-time{
    cursor: pointer;
}
.CompletionPopup-cancel-save-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.CompletionPopup-cancel-button,
.CompletionPopup-save-button{
    border: none;
    outline: none;
    padding: 5px 10px;
}

.CompletionPopup-cancel-button{
    background-color: #838383;
    color: #ffffff;
    padding: 5px 20px;
}

.CompletionPopup-save-button {
    background-color: #5472BF;
    color: #ffffff;
    padding: 5px 20px;
}

.CompletionPopup-task_completed-on{
    display: flex;
    flex-direction: column;
}

.CreateTaskPopupContainer-status-selection-option{
    display: flex;
    justify-content: space-between;
}


/* normal status button */
.CreateTaskPopupContainer-status-selection-option div{
    border: 2px solid #5472BF;
    color: #5472BF;
    padding: 5px 10px;
    border-radius: 36px;
    display: flex;
    align-items: center;
}
.CreateTaskPopupContainer-status-selection-option span{
   width: 18px;
   height: 18px;
   display: block;
   border: 1px solid #000000;
   border-radius: 36px;
}

/* selected status button */

.active_status_button{
    background-color:green !important;
    border: 2px solid rgb(126 197 126) !important;
    color: #ffffff !important;
}

.active_status_button span{
    border: 2px solid #f7f782 !important;
    background-color: #026c0f !important;
 }
 

 .ProjectProductManager-category-container{
    justify-content: unset;
 }

 .CompletionPopup-time-input {
    width: 30%;
}

@media(max-width: 575.98px){
    .CompletionPopup-side-clip-image {
        width: 30px;
        left: 10%;
    }

    .CompletionPopup-inner-card {
        width: 75%;
        height: 75vh;
        padding: 15px 20px 30px 25px;
    }
    .CreateTaskPopupContainer-status-selection-option {
        display: flex;
        flex-direction: column !important;
    }
    .CreateTaskPopupContainer-status-selection-option div {
        margin-bottom: 10px;
    }

    .CompletionPopup-select-text {
        color: #000000;
        font-weight: 600;
    }

    .CompletionPopup-top-header {
        border-bottom: 2px solid #767676;
        padding-bottom: 5px;
    }
    .CreateTaskPopupContainer-dropdown-picker {
        background-color: #dfdfdf !important;
        border: 2px solid #767676 !important;
        color: #000000 !important;
        min-height: 40px;
    }
}