.ViewDetailsOfIssue-root-container {
    background-image: linear-gradient(rgba(0, 0, 255, 0.2), rgba(255, 255, 0, 0.2)),
        url("../img/bg_details_of_page.png");
    height: 100vh;
    overflow: scroll;
}

.Ticket-remark-label {
    min-height: 50px;
    box-shadow: 1px 1px 4px #aeaeae;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #f3f3f3;
}

.TicketDetailsSupport-main-container{
    background-color: #ffffff;
    padding-bottom: 100px;
    border: 1px #808080 solid;
}

.View-details-write-reply-container{
    width: 90%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: end;
}

.View-details-send-reply-button{
    background-color: black;
    border: none;
    outline: none;
    color: #ffffff;
    padding: 5px 20px;
}

.TicketDetails-reply-to-this-log{
    width: calc(90% - 10px);
    margin: 0px auto;
    background-color: #f1f1f1;
    margin-top: 1px;
    padding: 10px 20px;
    border: 1px solid #cec5c5;
}

.View-details-reply-textarea {
    padding: 10px;
}